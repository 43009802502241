export const projectName = 'SitoBank';

export function getApiDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	let api = 'api';
	switch (process.env.NODE_ENV) {
		case 'dev':
		// case 'production':
		// 	api = 'dev-sito-manager-api';
		// 	hostname = 'seafintech.com';
		// 	protocol = 'https:';
		// 	break;
		case 'development':
			api = 'dev-sito-manager-api';
			hostname = 'seafintech.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		// hostname = 'test.payment-dev.com';
		// protocol = 'http:';
		api = 'test-sito-manager-api';
		hostname = 'seafintech.com';
		protocol = 'https:';
	}
	if (window.location.href.includes('uat')) {
		api = 'uat-sito-manager-api';
		hostname = 'seafintech.com';
		protocol = 'https:';
	}
	let domain = `${protocol}//${api}.${hostname}`;
	return domain;
}
export function getMerchantApiDomain() {
	let hostname = `merchant.${getDomain(location.hostname)}`;
	let protocol = location.protocol;
	let api = 'api';
	switch (process.env.NODE_ENV) {
		case 'dev':
		case 'development':
			api = 'dev-sito-merchant-api';
			hostname = 'seafintech.com';
			protocol = 'https:';
			break;
		// case 'production':
		// 	api = 'dev-sito-merchant-api';
		// 	hostname = 'seafintech.com';
		// 	protocol = 'https:';
		// 	break;
	}
	if (window.location.href.includes('test')) {
		api = 'test-sito-merchant-api';
		hostname = 'seafintech.com';
		protocol = 'https:';
	}
	if (window.location.href.includes('uat')) {
		api = 'uat-sito-merchant-api';
		hostname = 'seafintech.com';
		protocol = 'https:';
	}
	let domain = `${protocol}//${api}.${hostname}`;
	// let domain = `https://dev-merchant-api.payment-dev.com`;
	return domain;
}
export function getAgentApiDomain() {
	let hostname = `agent.${getDomain(location.hostname)}`;
	let protocol = location.protocol;
	let api = 'api';
	switch (process.env.NODE_ENV) {
		case 'dev':
		// case 'production':
		// 	api = 'dev-sito-agent-api';
		// 	hostname = 'seafintech.com';
		// 	protocol = 'https:';
		// 	break;
		case 'development':
			api = 'dev-sito-agent-api';
			hostname = 'seafintech.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		// api = 'test-agent-api';
		// hostname = 'payment-dev.com';
		// protocol = 'https:';
		api = 'test-sito-agent-api';
		hostname = 'seafintech.com';
		protocol = 'https:';
	}
	if (window.location.href.includes('uat')) {
		api = 'uat-sito-agent-api';
		hostname = 'seafintech.com';
		protocol = 'https:';
	}
	let domain = `${protocol}//${api}.${hostname}`;
	// let domain = `https://dev-agent-api.payment-dev.com`;
	return domain;
}

export function getWssDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = 'wss:';
	let subdomainName = 'api';
	if (hostname === 'sitobank.com') {
		subdomainName = 'api';
	} else {
		subdomainName = 'dev-sito-manager-api';
		hostname = 'seafintech.com';
	}
	if (window.location.href.includes('test')) {
		subdomainName = 'test-sito-manager-api';
		protocol = 'wss:';
	}
	if (window.location.href.includes('uat')) {
		subdomainName = 'uat-sito-manager-api';
		protocol = 'wss:';
	}
	// switch (process.env.NODE_ENV) {
	//   case 'dev':
	//   case 'development':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	//   case 'production':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	// }
	let domain = `${protocol}//${subdomainName}.${hostname}`;
	return domain;
}
export function getMerchantWssDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = 'wss:';
	let subdomainName = 'dev-sito-merchant-api';
	if (hostname === 'sitobank.com') {
		subdomainName = 'api.merchant';
	} else {
		hostname = 'seafintech.com';
	}
	if (window.location.href.includes('test')) {
		subdomainName = 'test-sito-merchant-api';
		protocol = 'wss:';
	}
	if (window.location.href.includes('uat')) {
		subdomainName = 'uat-sito-merchant-api';
		protocol = 'wss:';
	}
	// switch (process.env.NODE_ENV) {
	//   case 'dev':
	//   case 'development':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	//   case 'production':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	// }
	let domain = `${protocol}//${subdomainName}.${hostname}`;
	return domain;
}
export function getAgentWssDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = 'wss:';
	let subdomainName = 'dev-sito-agent-api';
	if (hostname === 'sitobank.com') {
		subdomainName = 'api.agent';
	} else {
		hostname = 'seafintech.com';
	}
	if (window.location.href.includes('test')) {
		subdomainName = 'test-sito-agent-api';
		protocol = 'wss:';
	}
	if (window.location.href.includes('uat')) {
		subdomainName = 'uat-sito-agent-api';
		protocol = 'wss:';
	}
	// switch (process.env.NODE_ENV) {
	//   case 'dev':
	//   case 'development':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	//   case 'production':
	//     hostname = 'payment-dev.com';
	//     subdomainName = 'api';
	//     break;
	// }
	let domain = `${protocol}//${subdomainName}.${hostname}`;
	return domain;
}

export function getExqueryDomain() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	let subdomainName = 'v2.exq';
	switch (process.env.NODE_ENV) {
		case 'dev':
		case 'development':
			hostname = 'payment-dev.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		subdomainName = 'test-sito-exquery';
		protocol = 'https:';
	}
	if (window.location.href.includes('uat')) {
		subdomainName = 'uat-sito-exquery';
		protocol = 'https:';
	}
	let domain = `${protocol}//${subdomainName}.${hostname}`;
	return domain;
}

function getDomain(hostname) {
	return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
}

export function getOrigin() {
	let hostname = getDomain(location.hostname);
	let protocol = location.protocol;
	let manager = 'manager';
	switch (process.env.NODE_ENV) {
		case 'dev':
			hostname = 'sitobank.com';
		case 'development':
			hostname = 'dev-sito-manager.seafintech.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		hostname = 'test-sito-manager.seafintech.com';
		protocol = 'https:';
	}
	if (window.location.href.includes('uat')) {
		hostname = 'uat-sito-manager.seafintech.com';
		protocol = 'https:';
	}
	let domain = `${protocol}//${hostname}`;
	return domain;
}
export function getMerchantOrigin() {
	let hostname = `merchant.${getDomain(location.hostname)}`;
	let protocol = location.protocol;
	let merchant = 'merchant';
	switch (process.env.NODE_ENV) {
		case 'dev':
			hostname = 'merchant.sitobank.com';
		case 'development':
			hostname = 'dev-sito-merchant.seafintech.com';
			protocol = 'https:';
			break;
	}
	if (window.location.href.includes('test')) {
		// hostname = 'test-merchant.seafintech.com';
		// protocol = 'https:';
		hostname = 'test-sito-merchant.seafintech.com';
		protocol = 'https:';
		// merchant = 'payment-test-merchant';
	}
	if (window.location.href.includes('uat')) {
		hostname = 'uat-sito-merchant.seafintech.com';
		protocol = 'https:';
	}
	let domain = `${protocol}//${hostname}`;
	return domain;
}
export function getAgentOrigin() {
	let hostname = `agent.${getDomain(location.hostname)}`;
	let protocol = location.protocol;
	let agent = 'agent';
	switch (process.env.NODE_ENV) {
		case 'dev':
			hostname = 'agent.sitobank.com';
		case 'development':
			hostname = 'dev-sito-agent.seafintech.com';
			protocol = 'https:';

			break;
	}
	if (window.location.href.includes('test')) {
		// hostname = 'test-agent.seafintech.com';
		// protocol = 'https:';
		hostname = 'test-sito-agent.seafintech.com';
		protocol = 'https:';
		// agent = 'payment-test-agent';
	}
	if (window.location.href.includes('uat')) {
		hostname = 'uat-sito-agent.seafintech.com';
		protocol = 'https:';
	}
	let domain = `${protocol}//${hostname}`;
	return domain;
}
